import { FormGroupState, ValidationErrors } from 'ngrx-forms';
import { ApplicationForm } from './application-form.state';

export interface GreaterThanDateValidationError {
    comparand: Date;
    actual: Date;
}

// tslint:disable-next-line:no-empty-interface
export interface AtLeastOneQualifyingMedicalConditionError {}

// tslint:disable-next-line:no-empty-interface
export interface IsNotOnParoleOrProbation {}

// @ts-ignore
declare module 'ngrx-forms' {
    export interface ValidationErrors {
        greaterThanDate?: GreaterThanDateValidationError;
        atLeastOneQualifyingMedicalCondition?: AtLeastOneQualifyingMedicalConditionError;
        isNotOnParoleOrProbation?: IsNotOnParoleOrProbation;
    }
}

export function greaterThanDate(comparand: string): (value: string) => ValidationErrors {
    return (value: string) => {
        if (!comparand || !value) {
            return null;
        }

        const dateComparand = new Date(comparand);
        const dateValue = new Date(value);

        if (dateValue.getTime() > dateComparand.getTime()) {
            return null;
        } else {
            return {
                greaterThanDate: {
                    comparand: dateComparand,
                    actual: dateValue
                }
            };
        }
    };
}

export function atLeastOneQualifyingMedicalCondition(
    frm: FormGroupState<ApplicationForm['medicalAndMentalHealth']>
): (value: boolean) => ValidationErrors {
    return _ => {
        const isValid = frm.value.postTraumaticStressDisability ||
            frm.value.traumaticBrainInjury ||
            frm.value.militarySexualTrauma;

        if (isValid) {
            return null;
        } else {
            return {
                atLeastOneQualifyingMedicalCondition: {}
            };
        }
    };
}

export function isNotOnParoleOrProbation(
    frm: FormGroupState<ApplicationForm['legalHistory']>
): (value: boolean) => ValidationErrors {
    return _ => {
        const isValid = frm.value.isCurrentlyOnParole === false &&
            frm.value.isCurrentlyOnProbation === false;

        if (isValid) {
            return null;
        } else {
            return {
                isNotOnParoleOrProbation: {}
            };
        }
    };
}
