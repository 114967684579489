import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
// Icons
import { faArrowRight, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { CreateAccountRequest } from 'src/app/models/account/create-account-request';
import { BadRequestResponse } from 'src/app/models/common';
import { AccountService } from 'src/app/services/account.service';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';


@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
    private passwordValidators = [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(100),
        CustomValidators.atLeastOneNumber(),
        CustomValidators.atLeastOneLowerCase(),
        CustomValidators.atLeastOneUpperCase(),
        CustomValidators.atLeastOneNonAlphanumeric()
    ];

    captchaToken: string;

    faArrowRight: IconDefinition = faArrowRight;

    signUpForm = new UntypedFormGroup({
        firstName: new UntypedFormControl(null, [
            Validators.required,
            Validators.minLength(1),
            Validators.maxLength(256)
        ]),
        lastName: new UntypedFormControl(null, [
            Validators.required,
            Validators.minLength(1),
            Validators.maxLength(256)
        ]),
        email: new UntypedFormControl(null, [
            Validators.required,
            Validators.email
        ]),
        password: new UntypedFormControl(null, this.passwordValidators),
        confirmPassword: new UntypedFormControl(null, this.passwordValidators),
        termsOfUseCheck: new UntypedFormControl(false, Validators.requiredTrue)
    }, [
        CustomValidators.passwordMatchValidator('password', 'confirmPassword')
    ]);

    modelErrors: string[] = [];

    constructor(private accountService: AccountService, private recaptchaV3Service: ReCaptchaV3Service) { }

    ngOnInit() {
        this.recaptchaV3Service.execute('signUpForm')
        .subscribe((token: string) => {
          this.captchaToken = token;
        });
    }

    async onSignUp() {
        this.modelErrors.length = 0;

        const getValue = (controlName: string) => this.signUpForm.controls[controlName].value;

        const request: CreateAccountRequest = {
            firstName: getValue('firstName'),
            lastName: getValue('lastName'),
            email: getValue('email'),
            password: getValue('password'),
            confirmPassword: getValue('confirmPassword'),
            captchaToken: this.captchaToken
        };

        const response = await this.accountService.createAccount(request);

        if (BadRequestResponse.isBadRequestResponse(response)) {
            if (Array.isArray(response)) {
                response.forEach(err => this.modelErrors.push(err));
            } else if (!!response.errors) {
                response.errors.forEach(err => this.modelErrors.push(err));
            } else {
                alert('An unexpected error occurred while trying to create your account.');
            }
        } else {
            window.location.href = response?.redirectUrl ?? '';
        }
    }

    get fg() {
        return this.signUpForm;
    }

    fc(controlName: string) {
        return this.signUpForm.controls[controlName];
    }

    isValid(controlName: string) {
        const c = this.fc(controlName);
        return c.valid && (c.dirty || c.touched);
    }

    isInvalid(controlName: string) {
        const c = this.fc(controlName);
        return c.invalid && (c.dirty || c.touched);
    }

    hasRequiredError(controlName: string) {
        return this.hasError(controlName, 'required');
    }

    hasError(controlName: string, errorType: string) {
        return this.isInvalid(controlName) && !!this.fc(controlName).errors[errorType];
    }

    hasMinLengthError(controlName: string) {
        return this.isInvalid(controlName) && !!this.fc(controlName).errors['minlength'];
    }

    minLength(controlName: string): number {
        return this.isInvalid(controlName) && this.fc(controlName).errors.minlength.requiredLength;
    }

    hasMaxLengthError(controlName: string) {
        return this.isInvalid(controlName) && !!this.fc(controlName).errors['maxlength'];
    }

    maxLength(controlName: string): number {
        return this.isInvalid(controlName) && this.fc(controlName).errors.maxlength.requiredLength;
    }

    json(obj: any): string {
        return JSON.stringify(obj);
    }
}

class CustomValidators {
    static passwordMatchValidator(passwordControlName: string, confirmPasswordControlName: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const password: string = control.get(passwordControlName).value;
            const confirmPassword: string = control.get(confirmPasswordControlName).value;

            // compare if the passwords match
            if (password !== confirmPassword) {
                // if they don't match, set an error in our confirmPassword form control
                control.get(confirmPasswordControlName).setErrors({ nopasswordmatch: true });
            }

            return null;
        };
    }

    static atLeastOneNumber(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const valid = /\d/.test(control.value);
            return valid ? null : { atLeastOneNumber: true };
        };
    }

    static atLeastOneLowerCase(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const valid = /[a-z]/.test(control.value);
            return valid ? null : { atLeastOneLowerCase: true };
        };
    }

    static atLeastOneUpperCase(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const valid = /[A-Z]/.test(control.value);
            return valid ? null : { atLeastOneUpperCase: true };
        };
    }

    static atLeastOneNonAlphanumeric(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const valid = /[\W_]+/.test(control.value);
            return valid ? null : { atLeastOneNonAlphanumeric: true };
        };
    }
}
