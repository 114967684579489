import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { environment } from '../environments/environment';

// Store/Reducers
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgrxFormsModule } from 'ngrx-forms';
import { applicationReducer } from './application/store/application.reducer';

// Top-Level Components
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { ApiAuthorizationModule } from 'src/api-authorization/api-authorization.module';
import { AuthorizeGuard } from 'src/api-authorization/authorize.guard';
import { AuthorizeInterceptor } from 'src/api-authorization/authorize.interceptor';
import { SignInComponent } from './home/sign-in/sign-in.component';
import { SignUpComponent } from './home/sign-up/sign-up.component';
import { ApplicationComponent } from './application/application.component';

// Application Components
import { PersonalInfoComponent } from './application/personal-info/personal-info.component';
import { HouseholdInfoComponent } from './application/household-info/household-info.component';
import { MilitaryInfoComponent } from './application/military-info/military-info.component';
import { BiographicalInfoComponent } from './application/biographical-info/biographical-info.component';
import { WorkEducationComponent } from './application/work-education/work-education.component';
import { TransportationComponent } from './application/transportation/transportation.component';
import { MedicalMentalHealthComponent } from './application/medical-mental-health/medical-mental-health.component';
import { LegalHistoryComponent } from './application/legal-history/legal-history.component';
import { MiscellaneousComponent } from './application/miscellaneous/miscellaneous.component';
import { ConsentComponent } from './application/consent/consent.component';
import { CertificationSignatureComponent } from './application/certification-signature/certification-signature.component';
import { ApplicationIntroComponent } from './application/application-intro/application-intro.component';

// Font Awesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ApplicationSubmittedComponent } from './application-submitted/application-submitted.component';
import { WarriorFormInputComponent } from './common/warrior-form-input/warrior-form-input.component';
import { WarriorFormSelectComponent } from './common/warrior-form-select/warrior-form-select.component';
import { ApplicationNavStepComponent } from './common/application-nav-step/application-nav-step.component';
import { WarriorNestedFormInputComponent } from './common/warrior-nested-form-input/warrior-nested-form-input.component';
import { HomeAddressFormComponent } from './common/home-address-form/home-address-form.component';
import { WarriorNestedFormSelectComponent } from './common/warrior-nested-form-select/warrior-nested-form-select.component';

// Captcha
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha-2';

@NgModule({ declarations: [
        AppComponent,
        NavMenuComponent,
        HomeComponent,
        CounterComponent,
        FetchDataComponent,
        SignInComponent,
        SignUpComponent,
        ApplicationComponent,
        PersonalInfoComponent,
        HouseholdInfoComponent,
        MilitaryInfoComponent,
        BiographicalInfoComponent,
        WorkEducationComponent,
        TransportationComponent,
        MedicalMentalHealthComponent,
        LegalHistoryComponent,
        MiscellaneousComponent,
        ConsentComponent,
        CertificationSignatureComponent,
        ApplicationIntroComponent,
        ApplicationSubmittedComponent,
        WarriorFormInputComponent,
        WarriorFormSelectComponent,
        ApplicationNavStepComponent,
        WarriorNestedFormInputComponent,
        HomeAddressFormComponent,
        WarriorNestedFormSelectComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        BrowserAnimationsModule,
        StoreModule.forRoot({ application: applicationReducer }),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
            autoPause: true
        }),
        FormsModule,
        ReactiveFormsModule,
        NgrxFormsModule,
        ApiAuthorizationModule,
        FontAwesomeModule,
        RecaptchaV3Module,
        RouterModule.forRoot([
            { path: '', component: HomeComponent, pathMatch: 'full' },
            { path: 'authentication/logged-out', pathMatch: 'full', redirectTo: '' },
            { path: 'application', component: ApplicationComponent, canActivate: [AuthorizeGuard] },
            { path: 'application/submitted', component: ApplicationSubmittedComponent, canActivate: [AuthorizeGuard] },
            { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) }
        ], {})], providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.siteKey },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
