<ng-container *ngIf="formState$ | async as formState">
    <form novalidate [ngrxFormState]="formState">
        <div class="border-bottom px-3 py-4">
            <h5 class="section-title">Consent to Contact</h5>
            <div class="d-flex">
                <input [ngrxFormControlState]="formState.controls.informationReleaseConsent" id="consent-to-contact"
                    name="consent-to-contact" type="checkbox" class="mr-1">
                <label for="consent-to-contact" class="w-100">I, <b>{{userFullName}}</b>, give consent for the individuals
                    listed
                    below to release to K9s For Warriors, Inc. information
                    relating to my health, mental health, and home/work/school environments. I understand that
                    the
                    information
                    requested is confidential, will not be released to any person or agency outside K9s For Warriors,
                    and will
                    be used
                    for the sole purpose of assessing my qualifications for a service dog and ability to provide a
                    suitable home
                    for a
                    service dog.</label>
            </div>
        </div>
        <div class="bg-form-field border-bottom px-3 py-4">
            <div class="d-flex justify-content-between align-items-start mb-3 responsive-form-field">
                <div class="d-flex flex-column w-25 form-field-container">
                    <h6 class="text-dark mb-0">Primary Doctor(s)</h6>
                    <small class="text-dark">Please list the names, addresses, and phone numbers of those who are
                        applicable.</small>
                </div>

                <div class="d-flex flex-column w-50" *ngIf="primaryDoctors$ | async as primaryDoctors">
                    <div class="border-bottom pb-4 form-field-container" [class.pt-2]="i > 0"
                        *ngFor="let group of primaryDoctors.controls; trackBy: trackByIndex; let i = index">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <h6 class="text-dark m-0">Doctor {{i + 1}}</h6>
                            <button *ngIf="i > 0" type="button" class="remove-dynamic-item-button"
                                (click)="removePrimaryDoctor(i)">
                                <fa-icon [icon]="faTimesCircle" class="text-yellow"></fa-icon>
                            </button>
                        </div>

                        <app-warrior-nested-form-input label="Doctor's Name" [control]="group.controls.name">
                        </app-warrior-nested-form-input>

                        <app-warrior-nested-form-input label="Phone Number" [control]="group.controls.phone"
                            inputType="phone"></app-warrior-nested-form-input>

                        <app-warrior-nested-form-input label="Street Address" [control]="group.controls.streetAddress">
                        </app-warrior-nested-form-input>

                        <app-warrior-nested-form-input label="Apartment/Unit/Suite/etc" [control]="group.controls.unit">
                        </app-warrior-nested-form-input>

                        <app-warrior-nested-form-input label="City" [control]="group.controls.city">
                        </app-warrior-nested-form-input>

                        <div class="d-flex justify-content-between">
                            <div class="mr-2">
                                <app-warrior-nested-form-select
                                    label="State"
                                    [control]="group.controls.stateOrProvince"
                                    [options]="statesAndTerritories"
                                    [valueSelector]="getCode"
                                    [nameSelector]="getName"
                                ></app-warrior-nested-form-select>
                            </div>

                            <div>
                                <app-warrior-nested-form-input label="Zip Code" [control]="group.controls.postalCode">
                                </app-warrior-nested-form-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-end">
                <button type="button" (click)="addPrimaryDoctor()" class="btn btn-secondary">ADD DOCTOR</button>
            </div>
        </div>

        <div class="bg-form-field border-bottom px-3 py-4">
            <div class="d-flex justify-content-between align-items-start mb-3 responsive-form-field">
                <div class="d-flex flex-column w-25 form-field-container">
                    <h6 class="text-dark mb-0">Psychologist/Psychiatrist</h6>
                    <small class="text-dark mb-2">Please list the names, addresses, and phone numbers of those who are
                        applicable.</small>
                </div>

                <div class="d-flex flex-column w-50" *ngIf="mentalHealthDoctors$ | async as mentalHealthDoctors">
                    <div class="border-bottom pb-4 form-field-container" [class.pt-2]="i > 0"
                        *ngFor="let group of mentalHealthDoctors.controls; trackBy: trackByIndex; let i = index">

                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <h6 class="text-dark m-0">Doctor {{i + 1}}</h6>
                            <button type="button" (click)="removeMentalHealthDoctor(i)"
                                class="remove-dynamic-item-button">
                                <fa-icon [icon]="faTimesCircle" class="text-yellow"></fa-icon>
                            </button>
                        </div>

                        <app-warrior-nested-form-input label="Doctor's Name" [control]="group.controls.name">
                        </app-warrior-nested-form-input>

                        <app-warrior-nested-form-input label="Phone Number" [control]="group.controls.phone"
                            inputType="phone"></app-warrior-nested-form-input>

                        <app-warrior-nested-form-input label="Street Address" [control]="group.controls.streetAddress">
                        </app-warrior-nested-form-input>

                        <app-warrior-nested-form-input label="Apartment/Unit/Suite/etc" [control]="group.controls.unit">
                        </app-warrior-nested-form-input>

                        <app-warrior-nested-form-input label="City" [control]="group.controls.city">
                        </app-warrior-nested-form-input>

                        <div class="d-flex justify-content-between">
                            <div class="mr-2">
                                <app-warrior-nested-form-select
                                    label="State"
                                    [control]="group.controls.stateOrProvince"
                                    [options]="statesAndTerritories"
                                    [valueSelector]="getCode"
                                    [nameSelector]="getName"
                                ></app-warrior-nested-form-select>
                            </div>

                            <div>
                                <app-warrior-nested-form-input label="Zip Code" [control]="group.controls.postalCode">
                                </app-warrior-nested-form-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-end">
                <button type="button" (click)="addMentalHealthDoctor()" class="btn btn-secondary">ADD
                    PSYCHOLOGIST/PSYCHIATRIST</button>
            </div>
        </div>

        <div class="bg-form-field border-bottomn px-3 py-4">
            <div class="d-flex justify-content-between align-items-start mb-3 responsive-form-field">
                <div class="d-flex flex-column w-25 form-field-container">
                    <h6 class="text-dark mb-0">Veterinarian</h6>
                    <small class="text-dark">Please list the names, addresses, and phone numbers of those who are
                        applicable.</small>
                </div>

                <div class="d-flex flex-column w-50" *ngIf="veterinarians$ | async as veterinarians">
                    <div class="border-bottom pb-4 form-field-container" [class.pt-2]="i > 0"
                        *ngFor="let group of veterinarians.controls; trackBy: trackByIndex; let i = index">

                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <h6 class="text-dark m-0">Vet 1</h6>
                            <button type="button" (click)="removeVeterinarian(i)"
                                class="remove-dynamic-item-button">
                                <fa-icon [icon]="faTimesCircle" class="text-yellow"></fa-icon>
                            </button>
                        </div>

                        <app-warrior-nested-form-input label="Doctor's Name" [control]="group.controls.name">
                        </app-warrior-nested-form-input>

                        <app-warrior-nested-form-input label="Phone Number" [control]="group.controls.phone"
                            inputType="phone"></app-warrior-nested-form-input>

                        <app-warrior-nested-form-input label="Street Address" [control]="group.controls.streetAddress">
                        </app-warrior-nested-form-input>

                        <app-warrior-nested-form-input label="Apartment/Unit/Suite/etc" [control]="group.controls.unit">
                        </app-warrior-nested-form-input>

                        <app-warrior-nested-form-input label="City" [control]="group.controls.city">
                        </app-warrior-nested-form-input>

                        <div class="d-flex justify-content-between">
                            <div class="mr-2">
                                <app-warrior-nested-form-select
                                    label="State"
                                    [control]="group.controls.stateOrProvince"
                                    [options]="statesAndTerritories"
                                    [valueSelector]="getCode"
                                    [nameSelector]="getName"
                                ></app-warrior-nested-form-select>
                            </div>

                            <div>
                                <app-warrior-nested-form-input label="Zip Code" [control]="group.controls.postalCode">
                                </app-warrior-nested-form-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-end">
                <button type="button" (click)="addVeterinarian()" class="btn btn-secondary">ADD VETERINARIAN</button>
            </div>
        </div>
        <div class="border-bottom px-3 py-4 d-flex justify-content-between align-items-center btn-container">
            <button class="btn btn-back" (click)="backToPreviousStep()">BACK TO SECTION 9 - MISCELLANEOUS</button>
            <button class="btn btn-primary" (click)="continueToNextStep()">CONTINUE TO SECTION 11 - CERTIFICATION AND
                SIGNATURE</button>
        </div>
    </form>
</ng-container>
